import * as _ from 'lodash';
import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import config from '../config';
import MainLayout from '../layouts/MainLayout';
import * as main from '../layouts/MainLayout.css';
import Article from '../components/Article';
import ArticlePoster from '../components/ArticlePoster';

interface PostTemplateProps {
  pathContext: {
    slug: string;
  };
  data: {
    logo: {
      childImageSharp: {
        fixed: any;
      };
    };
    markdownRemark: {
      html: string;
      htmlAst: any;
      excerpt: string;
      timeToRead: number;
      fields: {
        slug: string;
      };
      frontmatter: {
        lang: string;
        title: string;
        date: string;
        date_year: string;
        date_month: string;
        date_day: string;
        image: {
          childImageSharp: {
            fluid: any;
          };
        };
        tags: string[];
        author: {
          id: string;
          bio: string;
          avatar: {
            children: Array<{
              fixed: {
                src: string;
              };
            }>;
          };
        };
      };
    };
    relatedPosts: {
      totalCount: number;
      edges: Array<{
        node: {
          html: string;
          htmlAst: any;
          excerpt: string;
          timeToRead: number;
          frontmatter: {
            lang: string;
            title: string;
            date: string;
            date_year: string;
            date_month: string;
            date_day: string;
            image: {
              childImageSharp: {
                fluid: any;
              };
            };
            tags: string[];
            author: {
              id: string;
              bio: string;
              avatar: {
                children: Array<{
                  fixed: {
                    src: string;
                  };
                }>;
              };
            };
          };
          fields: {
            slug: string;
          };
        };
      }>;
    };
  };
  pageContext: {
    prev: PageContext;
    next: PageContext;
  };
}

export interface PageContext {
  excerpt: string;
  html: any;
  htmlAst: any;
  timeToRead: number;
  fields: {
    slug: string;
  };
  frontmatter: {
    image: {
      childImageSharp: {
        fluid: any;
      };
    };
    lang: string;
    title: string;
    date: string;
    date_year: string;
    date_month: string;
    date_day: string;
    tags: string[];
    author: {
      id: string;
      bio: string;
      avatar: {
        children: Array<{
          fixed: {
            src: string;
          };
        }>;
      };
    };
  };
}

const PostTemplate: React.FunctionComponent<PostTemplateProps> = props => {
  const post = props.data.markdownRemark;
  let width = '';
  let height = '';
  if (post.frontmatter.image) {
    width = post.frontmatter.image.childImageSharp.fluid.sizes.split(', ')[1].split('px')[0];
    height = String(Number(width) / post.frontmatter.image.childImageSharp.fluid.aspectRatio);
  }
  const twitter = config.twitter ? config.twitter.split('https://twitter.com/')[0] : "";
  return (
    <MainLayout className="post-template">
      <Helmet htmlAttributes={{ lang: post.frontmatter.lang || "en" }}>
        <title>{post.frontmatter.title}</title>
        <link rel="shortcut icon" href={props.data.logo.childImageSharp.fixed.src} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={post.frontmatter.title} />
        <meta property="og:description" content={post.excerpt} />
        <meta property="og:url" content={config.siteUrl + props.pathContext.slug} />
        {post.frontmatter.image && (
          <meta property="og:image" content={post.frontmatter.image.childImageSharp.fluid.src} />
        )}
        <meta property="article:published_time" content={post.frontmatter.date} />
        {post.frontmatter.tags && post.frontmatter.tags.length > 0 && (
          <meta property="article:tag" content={post.frontmatter.tags[0]} />
        )}
        <meta property="article:publisher" content={config.siteUrl} />
        <meta property="article:author" content={config.siteUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.frontmatter.title} />
        <meta name="twitter:description" content={post.excerpt} />
        <meta name="twitter:url" content={config.siteUrl + props.pathContext.slug} />
        {post.frontmatter.image && (
          <meta name="twitter:image" content={post.frontmatter.image.childImageSharp.fluid.src} />
        )}
        <meta name="twitter:label1" content="Written by" />
        <meta name="twitter:data1" content={post.frontmatter.author.id} />
        <meta name="twitter:label2" content="Filed under" />
        {post.frontmatter.tags && post.frontmatter.tags.length > 0 && (
          <meta name="twitter:data2" content={post.frontmatter.tags[0]} />
        )}
        <meta name="twitter:site" content={`@${twitter}`} />
        <meta name="twitter:creator" content={`@${twitter}`} />
        {width && (
          <meta property="og:image:width" content={width} />
        )}
        {height && (
          <meta property="og:image:height" content={height} />
        )}
      </Helmet>
      <Article className={main.main} post={post}/>
      <div className={main.also}>
        <div className={main.heading}>ALL POSTS</div>
        {props.data.relatedPosts.edges.map(p => {
          return (
            <Link className={"post-card-image-link"} to={p.node.fields.slug}>
              <ArticlePoster post={p.node}/>
            </Link>
          );
        })}
      </div>

      {/*
        <aside className={`read-next ${outer}`}>
          <div className={`${inner}`}>
            <ReadNextFeed>
              {props.data.relatedPosts && (
                <ReadNextCard tags={post.frontmatter.tags} relatedPosts={props.data.relatedPosts} />
              )}

              {props.pageContext.prev && <PostCard post={props.pageContext.prev} />}
              {props.pageContext.next && <PostCard post={props.pageContext.next} />}
            </ReadNextFeed>
          </div>
        </aside>
      */}
    </MainLayout>
  );
};

export default PostTemplate;

export const query = graphql`
  query($slug: String) {
    logo: file(relativePath: { eq: "img/nezaboodka.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    markdownRemark(
      fields: { slug: { eq: $slug } }
    ) {
      html
      htmlAst
      excerpt
      timeToRead
      fields {
        layout
        slug
      }
      frontmatter {
        lang
        title
        date
        date_year: date(formatString: "YYYY")
        date_month: date(formatString: "MMM")
        date_day: date(formatString: "D")
        tags
        image {
          childImageSharp {
            fluid(maxWidth: 3720) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        author {
          id
          bio
          avatar {
            children {
              ... on ImageSharp {
                fixed(quality: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
    relatedPosts: allMarkdownRemark(
      filter: { frontmatter: { draft: { ne: true } } },
      sort: { fields: [frontmatter___date], order: DESC },
      limit: 2000
    ) {
      totalCount
      edges {
        node {
          id
          timeToRead
          excerpt
          frontmatter {
            lang
            title
            date
            date_year: date(formatString: "YYYY")
            date_month: date(formatString: "MMM")
            date_day: date(formatString: "D")
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author {
              id
              bio
              avatar {
                children {
                  ... on ImageSharp {
                    fixed(quality: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
